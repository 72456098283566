@charset "UTF-8";
/* Css link*/
/* Css link*/
/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

/* 1. 기본 컬러 세팅 - form, layout, commons에 사용 */
/* 2. 값 세팅 - form, layout, commons, content에 사용  */
/* 3. 자주 쓰는 문법  */
.color-brand {
  color: #FF7800; }

.color-secondary {
  color: #3E66FB; }

.color-success {
  color: #3ac062; }

.color-info {
  color: #3bc1ff; }

.color-warning {
  color: #FFC107; }

.color-danger {
  color: #ff3333; }

.color-lightgray {
  color: #DCD9E1; }

.color-gray {
  color: #A8A7AC; }

.color-darkgray {
  color: #434245; }

.color-white {
  color: white; }

.color-black {
  color: black; }

.bg-brand {
  background-color: #FF7800; }
  .bg-brand.bg-light {
    background-color: #ffe4cc; }

.bg-secondary {
  background-color: #3E66FB; }
  .bg-secondary.bg-light {
    background-color: #d8e0fe; }

.bg-success {
  background-color: #3ac062; }
  .bg-success.bg-light {
    background-color: #d8f2e0; }

.bg-info {
  background-color: #3bc1ff; }
  .bg-info.bg-light {
    background-color: #d8f3ff; }

.bg-warning {
  background-color: #FFC107; }
  .bg-warning.bg-light {
    background-color: #fff3cd; }

.bg-danger {
  background-color: #ff3333; }
  .bg-danger.bg-light {
    background-color: #ffd6d6; }

.bg-gray {
  background-color: #A8A7AC; }
  .bg-gray.bg-light {
    background-color: #CAC8CF; }

.bg-lightgray {
  background-color: #DCD9E1; }
  .bg-lightgray.bg-light {
    background-color: #ECEAF2; }

.bg-darkgray {
  background-color: #434245; }
  .bg-darkgray.bg-light {
    background-color: #656467; }

/* 3. 자주 쓰는 문법  */
.ws-none {
  white-space: nowrap; }

.scale-1 {
  transform: scale(1.2); }

.scale-2 {
  transform: scale(1.5); }

.scale-3 {
  transform: scale(2); }

.scale-4 {
  transform: scale(3); }

.scale-5 {
  transform: scale(5); }

.tr {
  text-align: right; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.fr {
  float: right; }

.fl {
  float: left; }

.flx-1 {
  flex: 1; }

.flx-shrink-0 {
  flex-shrink: 0; }

.flx-full > * {
  width: 100%; }

.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: center; }
  .flx-row.gap-0 {
    gap: 0; }
  .flx-row.gap-1 {
    gap: .5rem; }
  .flx-row.gap-2 {
    gap: 1rem; }
  .flx-row.gap-3 {
    gap: 2rem; }
  .flx-row.row-top {
    align-items: unset; }

.flx-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: .5rem; }
  .flx-col.gap-0 {
    gap: 0; }
  .flx-col.gap-1 {
    gap: .5rem; }
  .flx-col.gap-2 {
    gap: 1rem; }
  .flx-col.gap-3 {
    gap: 2rem; }

.flx-sb {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flx-sb.flx-top {
    align-items: flex-start; }

.flx-center-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.flx-right {
  justify-content: flex-end !important; }

.flx-left {
  justify-content: flex-start !important; }

.flx-center {
  justify-content: center !important; }

.flx-bottom {
  align-content: flex-end !important;
  align-items: flex-end !important; }

.flx-top {
  align-content: flex-start !important;
  align-items: flex-start !important; }

.w-10 {
  width: 10% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-40 {
  width: 40% !important; }

.w-50 {
  width: 50% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.w-100 {
  width: 100% !important; }

.h-10 {
  height: 10% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-40 {
  height: 40% !important; }

.h-50 {
  height: 50% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.h-100 {
  height: 100% !important; }

.h-100-m {
  height: calc(100% - 1rem); }

.br-0 {
  border-radius: 0px; }

.br-1 {
  border-radius: .5rem; }

.br-2 {
  border-radius: 1rem; }

.br-3 {
  border-radius: 2rem; }

.br-4 {
  border-radius: 3rem; }

.br-5 {
  border-radius: 100rem; }

.min-w-none {
  min-width: unset !important; }

.max-w-none {
  max-width: unset !important; }

.min-h-none {
  min-height: unset !important; }

.max-h-none {
  max-height: unset !important; }

.show__under-mobile {
  display: none !important; }
  @media (max-width: 570px) {
    .show__under-mobile {
      display: block !important; } }

.show__under-tablet {
  display: none !important; }
  @media (max-width: 758px) {
    .show__under-tablet {
      display: block !important; } }

.show__under-desktop {
  display: none !important; }
  @media (max-width: 1200px) {
    .show__under-desktop {
      display: block !important; } }

.show__only-labtop {
  display: none !important; }
  @media (min-width: 1024px) and (max-width: 1700px) {
    .show__only-labtop {
      display: block !important; } }

.show__over-mobile {
  display: block !important; }
  @media (max-width: 570px) {
    .show__over-mobile {
      display: none !important; } }

.mini-box {
  border-radius: 5px;
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18);
  padding: 1rem;
  background-color: white; }

.shadow-none {
  box-shadow: none !important; }

.shadow-big {
  box-shadow: 0rem 0.47rem 2.19rem rgba(8, 10, 37, 0.03), 0rem 0.94rem 1.41rem rgba(8, 10, 37, 0.03), 0rem 0.25rem 0.53rem rgba(8, 10, 37, 0.05), 0rem 0.13rem 0.19rem rgba(8, 10, 37, 0.03) !important; }

.shadow-small {
  box-shadow: 1.5px 1.5px 4.5px rgba(29, 12, 23, 0.18) !important; }

#root .app-container.app-theme-pnt-light {
  color: rgba(0, 0, 0, 0.75); }
  #root .app-container.app-theme-pnt-light .monitoring-img {
    background-color: white; }
  #root .app-container.app-theme-pnt-light input, #root .app-container.app-theme-pnt-light textarea, #root .app-container.app-theme-pnt-light select, #root .app-container.app-theme-pnt-light table, #root .app-container.app-theme-pnt-light h1, #root .app-container.app-theme-pnt-light h2, #root .app-container.app-theme-pnt-light h3, #root .app-container.app-theme-pnt-light h4, #root .app-container.app-theme-pnt-light h5, #root .app-container.app-theme-pnt-light p, #root .app-container.app-theme-pnt-light span, #root .app-container.app-theme-pnt-light div {
    color: rgba(0, 0, 0, 0.75); }
  #root .app-container.app-theme-pnt-light .dashboard-title-wrapper {
    background: #FAFAFC; }
    #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-logo {
      color: #FF7800; }
    #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-title-time, #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-title-date {
      color: rgba(0, 0, 0, 0.75); }
    #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-title {
      background-color: #ECEAF2; }
      #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-title .dashboard-sub {
        color: rgba(0, 0, 0, 0.45); }
      #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-title .icon-next {
        filter: invert(0) saturate(0); }
      #root .app-container.app-theme-pnt-light .dashboard-title-wrapper .dashboard-title .dashboard-main {
        color: black; }
  #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right, #root .app-container.app-theme-pnt-light .card {
    background-color: white; }
    #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column::before, #root .app-container.app-theme-pnt-light .card .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column::before {
      background-color: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column .badge.badge-dot.badge-dot-xl, #root .app-container.app-theme-pnt-light .card .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column .badge.badge-dot.badge-dot-xl {
      box-shadow: 0 0 0 5px white; }
      #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column .badge.badge-dot.badge-dot-xl::before, #root .app-container.app-theme-pnt-light .card .vertical-timeline.vertical-timeline--animate.vertical-timeline--one-column .badge.badge-dot.badge-dot-xl::before {
        background-color: white; }
    #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .nav-item-divider, #root .app-container.app-theme-pnt-light .card .nav-item-divider {
      background: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .timeline-title, #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .vertical-timeline-element-content p, #root .app-container.app-theme-pnt-light .dropdown-menu-xl.rm-pointers.dropdown-menu.dropdown-menu-right .vertical-timeline-element-date span, #root .app-container.app-theme-pnt-light .card .timeline-title, #root .app-container.app-theme-pnt-light .card .vertical-timeline-element-content p, #root .app-container.app-theme-pnt-light .card .vertical-timeline-element-date span {
      color: rgba(0, 0, 0, 0.75) !important; }
  #root .app-container.app-theme-pnt-light .header-btn-lg .widget-content:hover {
    background-color: rgba(0, 0, 0, 0.05); }
  #root .app-container.app-theme-pnt-light .header-btn-lg::before {
    background: #ECEAF2; }
  #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right {
    background-color: white; }
    #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header {
      background-color: #3355d0;
      border-bottom: 1px solid #ECEAF2; }
      #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .user-name {
        color: white; }
      #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .unread-msg {
        color: rgba(255, 255, 255, 0.6); }
        #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .dropdown-menu-header .unread-msg b {
          color: white; }
    #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item:hover {
      background-color: rgba(0, 0, 0, 0.05); }
    #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item span[class*="icon-"] {
      background-color: white; }
    #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item .setting-name {
      color: grey; }
    #root .app-container.app-theme-pnt-light .header-btn-lg .rm-pointers.dropdown-menu-lg.dropdown-menu.dropdown-menu-right .setting-item::before {
      background-color: #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-modal li.active {
    background-color: #3E66FB; }
    #root .app-container.app-theme-pnt-light .pnt-modal li.active div {
      color: white; }
  #root .app-container.app-theme-pnt-light .app-header__logo {
    background-color: #FAFAFC; }
    #root .app-container.app-theme-pnt-light .app-header__logo .header__pane .hamburger-inner, #root .app-container.app-theme-pnt-light .app-header__logo .header__pane .hamburger-inner::before, #root .app-container.app-theme-pnt-light .app-header__logo .header__pane .hamburger-inner::after {
      filter: none; }
  #root .app-container.app-theme-pnt-light .app-header__content {
    background: #FAFAFC; }
    #root .app-container.app-theme-pnt-light .app-header__content .app-header-left {
      background-color: #ECEAF2;
      padding: .5rem 1rem;
      border-radius: 100rem; }
      #root .app-container.app-theme-pnt-light .app-header__content .app-header-left .menu-main {
        color: black; }
      #root .app-container.app-theme-pnt-light .app-header__content .app-header-left .icon-next {
        filter: invert(0) saturate(0); }
      #root .app-container.app-theme-pnt-light .app-header__content .app-header-left .menu-sub {
        color: rgba(0, 0, 0, 0.45); }
  @media (max-width: 1024px) {
    #root .app-container.app-theme-pnt-light .app-header {
      background: #FAFAFC; }
      #root .app-container.app-theme-pnt-light .app-header .app-header__content {
        background: linear-gradient(90deg, #ECEAF2, #ECEAF2); } }
  #root .app-container.app-theme-pnt-light .app-sidebar {
    background: #F6F6F9; }
    #root .app-container.app-theme-pnt-light .app-sidebar.nav-box__img-bg::before {
      background: linear-gradient(rgba(255, 255, 255, 0.95), rgba(227, 227, 233, 0.95)); }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .visible > .metismenu-item div.last-link-wrapper {
      border-bottom: 1px solid #DCD9E1; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .metismenu-icon {
      filter: invert(49%) sepia(82%) saturate(2656%) hue-rotate(1deg) brightness(105%) contrast(103%);
      top: 67%;
      left: 13px; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .metismenu-state-icon {
      color: #4d5675; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .metismenu-link span, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .last-custom-dashboard-link span {
      color: #000000;
      filter: none; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .metismenu-link button, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .last-custom-dashboard-link button {
      border-color: #FF7800 !important;
      color: #FF7800; }
      #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .metismenu-link button:hover, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .last-custom-dashboard-link button:hover {
        opacity: .7;
        background-color: transparent; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .metismenu-link:hover, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item .last-custom-dashboard-link:hover {
      background: #DCD9E1; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container::before {
      background-color: #DCD9E1; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li a > span {
      color: #4d5675; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li svg > path {
      color: #FF7800; }
    #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li.on, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li:hover, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li > a.active, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li > div > a.active, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li > a:hover, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container .last-custom-dashboard-link:hover, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container .last-custom-dashboard-link.active {
      background-color: #DCD9E1;
      border-radius: 0.25rem; }
      #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li.on a, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li:hover a, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li > a.active a, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li > div > a.active a, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container li > a:hover a, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container .last-custom-dashboard-link:hover a, #root .app-container.app-theme-pnt-light .app-sidebar .metismenu-container .metismenu-item > .metismenu-container .last-custom-dashboard-link.active a {
        color: black; }
  #root .app-container.app-theme-pnt-light.closed-sidebar .app-sidebar .metismenu-container .metismenu-item .visible > .metismenu-item div.last-link-wrapper {
    border-bottom: none; }
  #root .app-container.app-theme-pnt-light.closed-sidebar.sidebar-mobile-open .app-sidebar .metismenu-container .metismenu-item .visible > .metismenu-item div.last-link-wrapper {
    border-bottom: 1px solid #DCD9E1; }
  #root .app-container.app-theme-pnt-light .app-main__inner {
    background: linear-gradient(#ECEAF2, #ECEAF2); }
  #root .app-container.app-theme-pnt-light .app-page-title {
    background: #DCD9E1; }
  #root .app-container.app-theme-pnt-light .app-footer {
    background-color: #F6F6F9;
    color: rgba(0, 0, 0, 0.45); }
  #root .app-container.app-theme-pnt-light .card {
    background-color: white; }
    #root .app-container.app-theme-pnt-light .card .card-header {
      background-color: white;
      border-bottom: 1px solid #ECEAF2; }
    #root .app-container.app-theme-pnt-light .card .card-tab {
      background-color: #CAC8CF; }
      #root .app-container.app-theme-pnt-light .card .card-tab li {
        background-color: #DCD9E1; }
        #root .app-container.app-theme-pnt-light .card .card-tab li a {
          color: rgba(0, 0, 0, 0.45); }
        #root .app-container.app-theme-pnt-light .card .card-tab li.on {
          background-color: white; }
          #root .app-container.app-theme-pnt-light .card .card-tab li.on a {
            color: rgba(0, 0, 0, 0.85); }
    #root .app-container.app-theme-pnt-light .card .card-header__tab li a {
      color: rgba(0, 0, 0, 0.6); }
      #root .app-container.app-theme-pnt-light .card .card-header__tab li a::after {
        background-color: #656467; }
    #root .app-container.app-theme-pnt-light .card .card-header__tab li.on a {
      color: #656467; }
    #root .app-container.app-theme-pnt-light .card .card-button {
      border-top: 1px solid #ECEAF2;
      background-color: #fafafa; }
  #root .app-container.app-theme-pnt-light .pnt-modal {
    box-shadow: 0 0.76875rem 2.4875rem rgba(67, 66, 69, 0.3), 0 1.3375rem 1.70625rem rgba(67, 66, 69, 0.3), 0 0.55rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.225rem 0.4375rem rgba(67, 66, 69, 0.3); }
    #root .app-container.app-theme-pnt-light .pnt-modal .modal-header {
      color: rgba(0, 0, 0, 0.75);
      background-color: white;
      border-bottom: 1px solid #ECEAF2 !important; }
    #root .app-container.app-theme-pnt-light .pnt-modal .modal-body {
      color: rgba(0, 0, 0, 0.75);
      background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-modal .modal-body .pnt-label--group .label-options .dropdown-content .checked {
        background-color: #ECEAF2 !important; }
      #root .app-container.app-theme-pnt-light .pnt-modal .modal-body .pnt-label--group .label-options .dropdown-trigger {
        border: 1px solid #ECEAF2;
        background-color: white; }
        #root .app-container.app-theme-pnt-light .pnt-modal .modal-body .pnt-label--group .label-options .dropdown-trigger .tag-item input {
          border: none;
          background-color: white; }
        #root .app-container.app-theme-pnt-light .pnt-modal .modal-body .pnt-label--group .label-options .dropdown-trigger .tag-item input::placeholder {
          color: rgba(0, 0, 0, 0.75) !important; }
        #root .app-container.app-theme-pnt-light .pnt-modal .modal-body .pnt-label--group .label-options .dropdown-trigger .tag-item *::after {
          background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-modal .modal-body .pnt-label--group .label-options .dropdown-content {
        background-color: white; }
    #root .app-container.app-theme-pnt-light .pnt-modal .modal-footer {
      background-color: #fafafa;
      border-top: 1px solid #ECEAF2 !important; }
  #root .app-container.app-theme-pnt-light .pnt-datepicker-container .form-control:focus {
    outline: 0;
    box-shadow: 0 0 0 1px #656467; }
  #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker__input-container input {
    background-color: white;
    border: 1px solid #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-datepicker-container .input-group-text {
    background-color: #e2e8fe;
    border: 1px solid #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker {
    border-radius: 0;
    border: 1px solid #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker *::-webkit-scrollbar-track {
      background-color: #e2e8fe; }
    #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker *::-webkit-scrollbar-thumb {
      background-color: white; }
    #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__triangle {
      border-bottom-color: white;
      border-top-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__triangle::before {
        border-bottom-color: #ECEAF2;
        border-top-color: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__month-container {
      background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__month-container .react-datepicker__header {
        background-color: #e2e8fe;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: 1px solid #ECEAF2; }
        #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
          background-color: white;
          border-top: 1px solid #ECEAF2; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__month-container .react-datepicker__day:hover, #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__month-container .react-datepicker__day:focus, #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__month-container .react-datepicker__day:active {
        background-color: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__time-container {
      border-left: 1px solid #ECEAF2;
      background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__time-container .react-datepicker__header {
        background-color: #e2e8fe;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom: 1px solid #ECEAF2; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__time-container .react-datepicker__time {
        background-color: white; }
        #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-list-item:hover, #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-list-item:focus, #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-list-item:active {
          background-color: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container {
      background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container .react-datepicker-time__input {
        border-left: 1px solid #ECEAF2; }
        #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container .react-datepicker-time__input .react-datepicker__header {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          background-color: #e2e8fe;
          border-bottom: 1px solid #ECEAF2; }
        #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container .react-datepicker-time__input .time-label-box {
          border-bottom: 1px solid #ECEAF2; }
          #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container .react-datepicker-time__input .time-label-box div:last-child {
            border-left: 1px solid #ECEAF2; }
        #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container .react-datepicker-time__input .list-wrap button {
          color: rgba(0, 0, 0, 0.75);
          background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-datepicker-container .react-datepicker .react-datepicker__input-time-container .time-label-box {
        background-color: white; }
  #root .app-container.app-theme-pnt-light .react-select:hover {
    -webkit-filter: brightness(0.98);
    filter: brightness(0.98);
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  #root .app-container.app-theme-pnt-light .react-select .react-select__control {
    cursor: pointer;
    background-color: white;
    border: 1px solid #ECEAF2;
    box-shadow: none; }
  #root .app-container.app-theme-pnt-light .react-select .react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px #656467; }
  #root .app-container.app-theme-pnt-light .react-select .react-select__multi-value {
    background-color: #e2e8fe; }
    #root .app-container.app-theme-pnt-light .react-select .react-select__multi-value .react-select__multi-value__remove:hover, #root .app-container.app-theme-pnt-light .react-select .react-select__multi-value .react-select__multi-value__remove:focus, #root .app-container.app-theme-pnt-light .react-select .react-select__multi-value .react-select__multi-value__remove:active {
      background-color: #ECEAF2; }
  #root .app-container.app-theme-pnt-light .react-select .react-select__indicator-separator {
    display: none; }
  #root .app-container.app-theme-pnt-light .react-select .select-dropdown-svg {
    color: white; }
  #root .app-container.app-theme-pnt-light .react-select .react-select__indicator {
    opacity: .5; }
    #root .app-container.app-theme-pnt-light .react-select .react-select__indicator svg {
      height: 16px;
      width: 18px; }
  #root .app-container.app-theme-pnt-light .react-select .react-select__placeholder {
    font-size: 0.8rem; }
  #root .app-container.app-theme-pnt-light .react-select__menu {
    background-color: white; }
    #root .app-container.app-theme-pnt-light .react-select__menu .react-select__menu-list {
      padding: 0;
      border-radius: 5px; }
      #root .app-container.app-theme-pnt-light .react-select__menu .react-select__menu-list .react-select__option {
        cursor: pointer;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid #ECEAF2;
        background-color: white; }
        #root .app-container.app-theme-pnt-light .react-select__menu .react-select__menu-list .react-select__option:hover, #root .app-container.app-theme-pnt-light .react-select__menu .react-select__menu-list .react-select__option:focus, #root .app-container.app-theme-pnt-light .react-select__menu .react-select__menu-list .react-select__option:active {
          background-color: #ECEAF2; }
        #root .app-container.app-theme-pnt-light .react-select__menu .react-select__menu-list .react-select__option:last-child {
          border-bottom: none; }
  #root .app-container.app-theme-pnt-light .select-dropdown .styled-option:hover, #root .app-container.app-theme-pnt-light .select-dropdown .styled-option:focus, #root .app-container.app-theme-pnt-light .select-dropdown .styled-option:active {
    background-color: #ECEAF2; }
  #root .app-container.app-theme-pnt-light .select-dropdown .select-control {
    background-color: white;
    box-shadow: none; }
    #root .app-container.app-theme-pnt-light .select-dropdown .select-control:hover, #root .app-container.app-theme-pnt-light .select-dropdown .select-control:focus, #root .app-container.app-theme-pnt-light .select-dropdown .select-control:active {
      box-shadow: none;
      border: 1px solid #656467; }
  #root .app-container.app-theme-pnt-light .select-dropdown .select-container {
    background-color: white;
    border: 1px solid #ECEAF2;
    box-shadow: none; }
    #root .app-container.app-theme-pnt-light .select-dropdown .select-container.container-open {
      box-shadow: 0 0 0 1px #656467; }
    #root .app-container.app-theme-pnt-light .select-dropdown .select-container:hover {
      -webkit-filter: brightness(0.98);
      filter: brightness(0.98);
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
  #root .app-container.app-theme-pnt-light .select-dropdown .select-menu {
    background-color: white; }
  #root .app-container.app-theme-pnt-light .select-dropdown .select-option > div {
    background-color: white; }
    #root .app-container.app-theme-pnt-light .select-dropdown .select-option > div:hover, #root .app-container.app-theme-pnt-light .select-dropdown .select-option > div:focus, #root .app-container.app-theme-pnt-light .select-dropdown .select-option > div:active {
      background-color: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .select-dropdown .select-option > div .select-label {
      color: rgba(0, 0, 0, 0.75); }
  #root .app-container.app-theme-pnt-light .select-dropdown .select-group-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.75);
    padding: 4px 8px;
    font-weight: bold; }
  #root .app-container.app-theme-pnt-light .select-dropdown .rct-icon {
    color: rgba(0, 0, 0, 0.75); }
  #root .app-container.app-theme-pnt-light .select-dropdown .tree-wrapper {
    border: 1px solid rgba(0, 0, 0, 0.75);
    padding: 5px; }
  #root .app-container.app-theme-pnt-light .select-dropdown .select-dropdown-svg {
    opacity: .5; }
  #root .app-container.app-theme-pnt-light .rbt *::-webkit-scrollbar-track {
    background-color: #e2e8fe; }
  #root .app-container.app-theme-pnt-light .rbt *::-webkit-scrollbar-thumb {
    background-color: white; }
  #root .app-container.app-theme-pnt-light .rbt .rbt-input-hint-container .rbt-input-main {
    box-shadow: none;
    color: rgba(0, 0, 0, 0.75);
    background-color: white;
    border: 1px solid #ECEAF2; }
    #root .app-container.app-theme-pnt-light .rbt .rbt-input-hint-container .rbt-input-main:hover {
      -webkit-filter: brightness(0.98);
      filter: brightness(0.98);
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
    #root .app-container.app-theme-pnt-light .rbt .rbt-input-hint-container .rbt-input-main.focus {
      box-shadow: 0 0 0 1px #656467; }
  #root .app-container.app-theme-pnt-light .rbt .dropdown-menu {
    padding: 0;
    border-radius: 5px;
    background-color: white;
    z-index: 1001; }
    #root .app-container.app-theme-pnt-light .rbt .dropdown-menu::after, #root .app-container.app-theme-pnt-light .rbt .dropdown-menu::before {
      display: none; }
    #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li {
      border-bottom: 1px solid #ECEAF2; }
      #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li .dropdown-item:hover, #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li .dropdown-item:focus, #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li .dropdown-item:active {
        background-color: #ECEAF2; }
      #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li[role='option']:last-of-type {
        border-bottom: none; }
      #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li.divider {
        margin: 0; }
      #root .app-container.app-theme-pnt-light .rbt .dropdown-menu li.rbt-menu-pagination-option {
        color: rgba(0, 0, 0, 0.75); }
    #root .app-container.app-theme-pnt-light .rbt .dropdown-menu .dropdown-item {
      color: rgba(0, 0, 0, 0.75); }
      #root .app-container.app-theme-pnt-light .rbt .dropdown-menu .dropdown-item > span > * {
        color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .widget-add-popup .list-group .list-group-item {
    background-color: white;
    color: rgba(0, 0, 0, 0.6); }
    #root .app-container.app-theme-pnt-light .widget-add-popup .list-group .list-group-item button {
      background-color: #FF7800;
      border: #FF7800; }
  #root .app-container.app-theme-pnt-light .widget-add-popup .list-group .badge {
    color: #fff;
    background-color: #FF7800;
    border: #FF7800; }
  #root .app-container.app-theme-pnt-light .toast {
    background-color: white; }
    #root .app-container.app-theme-pnt-light .toast .toast-header {
      background-color: white;
      border-bottom: 1px solid #ECEAF2 !important; }
    #root .app-container.app-theme-pnt-light .toast .toast-body {
      background-color: white;
      font-size: 0.9rem;
      font-weight: 300; }
    #root .app-container.app-theme-pnt-light .toast .toast-footer {
      background-color: #fafafa;
      border-top: 1px solid #ECEAF2 !important; }
    #root .app-container.app-theme-pnt-light .toast .filtering-container {
      border: 1px solid #ECEAF2; }
    #root .app-container.app-theme-pnt-light .toast .rct-icon {
      color: rgba(0, 0, 0, 0.6); }
      #root .app-container.app-theme-pnt-light .toast .rct-icon.rct-icon-check {
        color: black; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-brand {
    background-color: #FF7800; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-secondary {
    background-color: #3E66FB; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-success {
    background-color: #3acc5c; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-warning {
    background-color: #FFC107; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-danger {
    background-color: #ff3333; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-info {
    background-color: #03D0FF; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-lightgray {
    background-color: #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-gray {
    background-color: #A8A7AC; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-darkgray {
    background-color: #656467; }
  #root .app-container.app-theme-pnt-light .pnt-btn.btn-disable {
    background-color: #f9f9fb; }
  #root .app-container.app-theme-pnt-light .pnt-input--group input {
    background-color: white;
    border: 1px solid #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-input--group input::placeholder {
      color: rgba(0, 0, 0, 0.45); }
  #root .app-container.app-theme-pnt-light .pnt-input--group input:focus, #root .app-container.app-theme-pnt-light .pnt-input--group input:active, #root .app-container.app-theme-pnt-light .pnt-input--group input:focus-visible {
    border: 1px solid #656467; }
  #root .app-container.app-theme-pnt-light .pnt-input--group.input-error p, #root .app-container.app-theme-pnt-light .pnt-input--group.input-error b, #root .app-container.app-theme-pnt-light .pnt-input--group.input-error span, #root .app-container.app-theme-pnt-light .pnt-input--group.input-error div {
    color: #ff3333; }
  #root .app-container.app-theme-pnt-light .pnt-input--group.input-error .pnt-input {
    border: 1px solid #ff3333; }
  #root .app-container.app-theme-pnt-light .pnt-input--group.form-must p, #root .app-container.app-theme-pnt-light .pnt-input--group.form-must b, #root .app-container.app-theme-pnt-light .pnt-input--group.form-must span, #root .app-container.app-theme-pnt-light .pnt-input--group.form-must div {
    color: #ff3333; }
  #root .app-container.app-theme-pnt-light .pnt-input--group.form-must input {
    border: 1px solid #FF7800; }
  #root .app-container.app-theme-pnt-light .pnt-input--group.input-disable .pnt-input {
    background-color: #F6F6F9; }
  #root .app-container.app-theme-pnt-light .pnt-input--group.input-disable::placeholder {
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .pnt-file--group input[type="file"]::before {
    background-color: white; }
  #root .app-container.app-theme-pnt-light .pnt-file--group input[type="file"]::after {
    border: 1px solid #A8A7AC; }
  #root .app-container.app-theme-pnt-light .pnt-select--group .select__btn {
    color: rgba(0, 0, 0, 0.6);
    background-color: white;
    border: 1px solid #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-select--group .select__options {
    background-color: white;
    border: 1px solid #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-select--group .select__options li a {
      border-top: 1px solid #ECEAF2;
      color: rgba(0, 0, 0, 0.6); }
    #root .app-container.app-theme-pnt-light .pnt-select--group .select__options li:hover a {
      background-color: #ECEAF2; }
    #root .app-container.app-theme-pnt-light .pnt-select--group .select__options li.on a {
      background-color: #3E66FB;
      color: black; }
  #root .app-container.app-theme-pnt-light .pnt-select--group.on .select__btn {
    border: 1px solid #656467; }
  #root .app-container.app-theme-pnt-light .pnt-select--group.on .select__options li.on {
    background-color: #3E66FB; }
    #root .app-container.app-theme-pnt-light .pnt-select--group.on .select__options li.on a {
      color: white; }
  #root .app-container.app-theme-pnt-light .pnt-select--group.form-must span {
    color: #ff3333; }
  #root .app-container.app-theme-pnt-light .pnt-select--group.form-must .select__btn {
    border: 1px solid #FF7800; }
  #root .app-container.app-theme-pnt-light .pnt-checkbox p {
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .pnt-checkbox .checkbox-effect {
    border: 1px solid #ECEAF2;
    background-color: white; }
    #root .app-container.app-theme-pnt-light .pnt-checkbox .checkbox-effect::before, #root .app-container.app-theme-pnt-light .pnt-checkbox .checkbox-effect::after {
      background: white; }
  #root .app-container.app-theme-pnt-light .pnt-checkbox.check-checked .checkbox-effect {
    background-color: #3E66FB; }
  #root .app-container.app-theme-pnt-light .pnt-checkbox.check-checked p {
    color: black; }
  #root .app-container.app-theme-pnt-light .pnt-toggle input:checked + .slider {
    background-color: #3acc5c; }
  #root .app-container.app-theme-pnt-light .pnt-toggle .slider {
    background-color: #DCD9E1; }
    #root .app-container.app-theme-pnt-light .pnt-toggle .slider::before {
      background-color: white; }
  #root .app-container.app-theme-pnt-light .pnt-toggle.toggle-txt p:nth-of-type(1) {
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .pnt-toggle.toggle-txt p:nth-of-type(2) {
    color: white; }
  #root .app-container.app-theme-pnt-light .pnt-toggle.toggle-icon div {
    filter: invert(1); }
  #root .app-container.app-theme-pnt-light .pnt-radio li {
    border: 1px solid #ECEAF2;
    background-color: transparent; }
    #root .app-container.app-theme-pnt-light .pnt-radio li label {
      color: rgba(0, 0, 0, 0.6); }
    #root .app-container.app-theme-pnt-light .pnt-radio li.checked {
      background-color: #434245; }
      #root .app-container.app-theme-pnt-light .pnt-radio li.checked label {
        color: black; }
  #root .app-container.app-theme-pnt-light .pnt-nameplate {
    background-color: #DCD9E1;
    color: rgba(0, 0, 0, 0.75); }
    #root .app-container.app-theme-pnt-light .pnt-nameplate::before {
      background-color: white; }
  #root .app-container.app-theme-pnt-light .pnt-txt {
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .pnt-border {
    background-color: #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-label--group .label-main.label-dot::before {
    background-color: #FF7800; }
  #root .app-container.app-theme-pnt-light .pnt-label--group .label-main.label-must {
    color: #FF7800; }
  #root .app-container.app-theme-pnt-light .pagination .now {
    color: #FF7800; }
  #root .app-container.app-theme-pnt-light .pagination .whole {
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .pnt-table .border-box {
    border-top: 1.5px solid #8ba3fd;
    border-bottom: 1.5px solid #8ba3fd; }
    #root .app-container.app-theme-pnt-light .pnt-table .border-box .th {
      background-color: #e2e8fe;
      border-right: 1px solid #ECEAF2;
      border-bottom: 1px solid #ECEAF2;
      color: #2b47b0; }
    #root .app-container.app-theme-pnt-light .pnt-table .border-box .tbody {
      background-color: white; }
      #root .app-container.app-theme-pnt-light .pnt-table .border-box .tbody .tr:nth-child(2n) {
        background-color: #F6F6F9; }
      #root .app-container.app-theme-pnt-light .pnt-table .border-box .tbody .tr .td {
        color: rgba(0, 0, 0, 0.6);
        border-bottom: 1px solid #ECEAF2;
        border-right: 1px solid #ECEAF2; }
  #root .app-container.app-theme-pnt-light .pnt-table.table-clickable .border-box .tr:hover {
    background-color: #ECEAF2 !important; }
  #root .app-container.app-theme-pnt-light .pnt-table.table-clickable .border-box .tr.active {
    background-color: #8ba3fd !important; }
    #root .app-container.app-theme-pnt-light .pnt-table.table-clickable .border-box .tr.active .td {
      color: white; }
  #root .app-container.app-theme-pnt-light .form-disable.pnt-btn {
    background-color: #F6F6F9;
    box-shadow: 0 0 0 1px #ECEAF2 inset;
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .form-disable.pnt-input--group .pnt-input {
    background-color: #F6F6F9;
    color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .form-disable.pnt-select--group .select__btn {
    background-color: #F6F6F9; }
    #root .app-container.app-theme-pnt-light .form-disable.pnt-select--group .select__btn .cont-wrap {
      color: rgba(0, 0, 0, 0.6); }
  #root .app-container.app-theme-pnt-light .form-disable.pnt-checkbox .checkbox-effect {
    background-color: #F6F6F9; }
  #root .app-container.app-theme-pnt-light *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); }
  #root .app-container.app-theme-pnt-light *::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); }
  #root .app-container.app-theme-pnt-light .color-brand {
    color: #FF7800 !important; }
  #root .app-container.app-theme-pnt-light .color-secondary {
    color: #3E66FB !important; }
  #root .app-container.app-theme-pnt-light .color-success {
    color: #3acc5c !important; }
  #root .app-container.app-theme-pnt-light .color-info {
    color: #03D0FF !important; }
  #root .app-container.app-theme-pnt-light .color-warning {
    color: #FFC107 !important; }
  #root .app-container.app-theme-pnt-light .color-danger {
    color: #ff3333 !important; }
  #root .app-container.app-theme-pnt-light .color-lightgray {
    color: #ECEAF2 !important; }
  #root .app-container.app-theme-pnt-light .color-gray {
    color: #A8A7AC !important; }
  #root .app-container.app-theme-pnt-light .color-darkgray {
    color: #656467 !important; }
  #root .app-container.app-theme-pnt-light .color-white {
    color: white !important; }
  #root .app-container.app-theme-pnt-light .color-black {
    color: black !important; }
  #root .app-container.app-theme-pnt-light .bg-brand {
    background-color: #FF7800 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-light {
      background-color: #ffb573 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-dark {
      background-color: #8c4200 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-d-4 {
      background-color: #663000 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-d-3 {
      background-color: #8c4200 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-d-2 {
      background-color: #b35400 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-d-1 {
      background-color: #d46400 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-l-4 {
      background-color: #ff9333 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-l-3 {
      background-color: #ffb573 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-l-2 {
      background-color: #ffd7b3 !important; }
    #root .app-container.app-theme-pnt-light .bg-brand.bg-l-1 {
      background-color: #fff2e6 !important; }
  #root .app-container.app-theme-pnt-light .bg-secondary {
    background-color: #3E66FB !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-light {
      background-color: #8ba3fd !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-dark {
      background-color: #22388a !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-d-4 {
      background-color: #192964 !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-d-3 {
      background-color: #22388a !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-d-2 {
      background-color: #2b47b0 !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-d-1 {
      background-color: #3355d0 !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-l-4 {
      background-color: #6182fc !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-l-3 {
      background-color: #8ba3fd !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-l-2 {
      background-color: #bbc9fe !important; }
    #root .app-container.app-theme-pnt-light .bg-secondary.bg-l-1 {
      background-color: #e2e8fe !important; }
  #root .app-container.app-theme-pnt-light .bg-success {
    background-color: #3acc5c !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-light {
      background-color: #89e09d !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-dark {
      background-color: #207033 !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-d-4 {
      background-color: #175225 !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-d-3 {
      background-color: #207033 !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-d-2 {
      background-color: #298f40 !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-d-1 {
      background-color: #30a94c !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-l-4 {
      background-color: #5dd579 !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-l-3 {
      background-color: #89e09d !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-l-2 {
      background-color: #baedc6 !important; }
    #root .app-container.app-theme-pnt-light .bg-success.bg-l-1 {
      background-color: #dcf6e2 !important; }
  #root .app-container.app-theme-pnt-light .bg-info {
    background-color: #03D0FF !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-light {
      background-color: #81e8ff !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-dark {
      background-color: #02728c !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-d-4 {
      background-color: #015366 !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-d-3 {
      background-color: #02728c !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-d-2 {
      background-color: #0292b3 !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-d-1 {
      background-color: #02add4 !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-l-4 {
      background-color: #42dcff !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-l-3 {
      background-color: #81e8ff !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-l-2 {
      background-color: #b3f1ff !important; }
    #root .app-container.app-theme-pnt-light .bg-info.bg-l-1 {
      background-color: #e6faff !important; }
  #root .app-container.app-theme-pnt-light .bg-warning {
    background-color: #FFC107 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-light {
      background-color: #ffdd77 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-dark {
      background-color: #947004 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-d-4 {
      background-color: #664d03 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-d-3 {
      background-color: #947004 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-d-2 {
      background-color: #bf9105 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-d-1 {
      background-color: #e6ae06 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-l-4 {
      background-color: #ffd145 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-l-3 {
      background-color: #ffdd77 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-l-2 {
      background-color: #ffe9a8 !important; }
    #root .app-container.app-theme-pnt-light .bg-warning.bg-l-1 {
      background-color: #fff3cd !important; }
  #root .app-container.app-theme-pnt-light .bg-danger {
    background-color: #ff3333 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-light {
      background-color: #ff9999 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-dark {
      background-color: #8c1c1c !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-d-4 {
      background-color: #661414 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-d-3 {
      background-color: #8c1c1c !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-d-2 {
      background-color: #b32424 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-d-1 {
      background-color: #d42a2a !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-l-4 {
      background-color: #ff6666 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-l-3 {
      background-color: #ff9999 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-l-2 {
      background-color: #ffc2c2 !important; }
    #root .app-container.app-theme-pnt-light .bg-danger.bg-l-1 {
      background-color: #ffebeb !important; }
  #root .app-container.app-theme-pnt-light .bg-gray {
    background-color: #A8A7AC !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-light {
      background-color: #e5e5e6 !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-dark {
      background-color: #323234 !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-d-4 {
      background-color: #434245 !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-d-3 {
      background-color: #656467 !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-d-2 {
      background-color: #86858A !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-d-1 {
      background-color: #A8A7AC !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-l-4 {
      background-color: #CAC8CF !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-l-3 {
      background-color: #DCD9E1 !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-l-2 {
      background-color: #ECEAF2 !important; }
    #root .app-container.app-theme-pnt-light .bg-gray.bg-l-1 {
      background-color: #F6F6F9 !important; }
  #root .app-container.app-theme-pnt-light .bg-lightgray {
    background-color: #ECEAF2 !important; }
    #root .app-container.app-theme-pnt-light .bg-lightgray.bg-light {
      background-color: #f9f9fb !important; }
    #root .app-container.app-theme-pnt-light .bg-lightgray.bg-dark {
      background-color: #474649 !important; }
  #root .app-container.app-theme-pnt-light .bg-darkgray {
    background-color: #656467 !important; }
    #root .app-container.app-theme-pnt-light .bg-darkgray.bg-light {
      background-color: #d1d1d1 !important; }
    #root .app-container.app-theme-pnt-light .bg-darkgray.bg-dark {
      background-color: #1e1e1f !important; }
