@import "../pnt/tools.scss";

.btn-white{
  background-color: white;
  border: 1px solid $gray-700;
}

//
.app-theme-pnt-dark{
    .custom-action-btn{
      color:  white !important;
      background-color: transparent !important;
    }
  .bg-transparent-btn{
    background-color: transparent !important;
  }
}
.app-theme-pnt-light,.app-theme-pnt-navy {
  .custom-action-btn {
    color: #6748E3 !important;
  }
}

.text-overflow{
  overflow-y: scroll;
}

.min-h-40 {
  min-height: 40px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.lostSignal-marker{
  width: 15px;
  height: 15px;
  transform: translate(7.5px 7.5px);
  background-color: #919090;
  border-radius: 50%;
}

.simple-marker{
  width: 15px;
  height: 15px;
  transform: translate(7.5px 7.5px);
  background-color: #3076c1;
  border-radius: 50%;
}

.play-bar {
  border: none;
  width: 100%;
  margin: 1rem 0;
  box-shadow: none;

  & .noUi-base{
    cursor: pointer;
    background-color: #dedede;
    & .noUi-connect {
      background-color: #ff9820;
    }
    & .noUi-origin{
      margin-right: 1rem;

      & .noUi-handle{
        cursor: pointer;
        box-shadow: none;
        background-color: #e66b00;
        width: 1rem;
        height: 1rem;
        border: 1px solid #e66b00;
        border-radius: 50%;
        &::before{
          display: none;
        }
        &::after{
          display: none;
        }
      }
    }
  }
}

.analysis-layout{
  display: grid;
  column-gap: 10px;
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 2fr;
  }
}

.analysis-list-height{
  height: 730px;
  @media screen and (max-width: 1200px) {
    height: 500px;
  }
}

.analysis-viewer-height{
  height: 730px;
}

.quick-search-height{
  height: 785px;
}

.search-icon-div{
  background-color: #b1e8f0;
  width: 44px;
  height: 44px;
  margin-right: 10px;
  outline: none;
  border-radius: 5px;
}

.search-icon{
  color: #10b6cc;
  margin-left: 0.1rem;
  font-size: 1rem;
  margin-right: 10px;
}

.search-wrap {
  .pnt-btn.btn-icon {
    min-width: 5.8rem;
  }
}

.table-btn{
  height: 27px;
}

.pnt-nameplate {
  .transform-scale-bigger {
    transform: scale(1.6) translateX(1.5px);
  }
}

.select-menu {
  .disabled-select-option {
    cursor: not-allowed !important;

    & > div, & > label {
      pointer-events: none;
    }
  }
  .disabled-tree-option {
    .rct-text {
      cursor: not-allowed !important;
      &>label {
        pointer-events: none;
      }
    }
  }

  .disabled-option {
    filter: opacity(0.5);
    .styled-option {
      cursor: not-allowed;
      &>label {
        pointer-events: none;
      }
    }
  }
}
.app-theme-pnt-dark .right-wrapper .right-header{
  background-color: #2C2C30;
}

.app-theme-pnt-plant .right-wrapper .right-header{
  background-color: #344545;
}

.right-wrapper {
  width: 100%;
  height: 16rem;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  margin-right: 2rem;
  overflow: auto;
  .right-header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    background-color: #f2f2f2;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 2rem 1rem;
    border-bottom: 1px solid #cfcfcf;
  }
  .transform-scale-medium {
    transform:scale(1.3);
  }
}

.block-ui.cursor-not-allowed .block-ui-container {
  cursor: not-allowed;
}

// 사이드바 하단
.app-main {
  .pnt-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .pnt-sidebar-bottom {
      padding: 1rem 1.5rem 1.5rem;
      .menu-border-box {
        border-top: 1px solid var(--font-black);
      }
    }
    .app-sidebar__inner {
      max-height: calc(100vh - 145px);
    }
  }
}

.closed-sidebar .app-main .pnt-sidebar .metismenu-container .menu-border-box {
  border: none;
}
.closed-sidebar .app-main .app-sidebar.pnt-sidebar:hover .scrollbar-container .metismenu-container.visible .menu-border-box {
  border-bottom: 1px solid var(--blue);
}
.closed-sidebar .app-main .app-sidebar.pnt-sidebar:hover .pnt-sidebar-bottom .menu-border-box {
  border-top: 1px solid var(--font-black);
}
.closed-sidebar:not(.sidebar-mobile-open) .app-sidebar:hover .scrollbar-container {
  height: calc(100% - 140px);
}
// 사이드바 하단 종료 //

.cursor-default{
  cursor: default;
}

.filter_ver4 .filter-box:last-child {
  margin-bottom: 0;
}